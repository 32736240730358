// Pendo snippet
((apiKey) => {
    ((p, e, n, d, o) => {
        let v;
        let w;
        let x;
        let y;
        let z;
        o = p[d] = p[d] || {};
        o._q = o._q || [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
        for (w = 0, x = v.length; w < x; ++w) {
            ((m) => {
                o[m] =
                    o[m] ||
                    (() => {
                        o._q[m === v[0] ? 'unshift' : 'push'](
                            [m].concat([].slice.call(arguments, 0)),
                        );
                    });
            })(v[w]);
        }
        y = e.createElement(n);
        y.async = !0;
        y.src = `https://content.flowengagement.boomi.com/agent/static/${apiKey}/pendo.js`;
        z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);
    })(window, document, 'script', 'pendo');
})('c9f17b5c-49a2-42df-49dd-bae3a8931eab');
